import { createAsyncThunk } from "@reduxjs/toolkit";
import { ShowProfileAPI, UpdateProfileAPI, } from "../Api";
import axiosInstance, { errorMessage } from "../constant";

export const ShowProfileAction = createAsyncThunk("admin/profile/show", async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(ShowProfileAPI);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateProfileAction = createAsyncThunk("admin/profile/update", async (info, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(UpdateProfileAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
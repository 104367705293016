export const formatNumberWithCommas = (number) => {
    if (number)
        return number.toLocaleString();
    return null
}

export const convertSyrianPhoneNumber = (phoneNumber) => {
    if (phoneNumber?.startsWith('09')) {
        return ('+963' + phoneNumber.substring(1))
    }
    if (phoneNumber?.startsWith('9')) {
        return ('+963' + phoneNumber)
    }
    return phoneNumber;
}

export function formatNumberWithAbbreviations(number) {
    if (number >= 1000000) {
        return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (number >= 1000) {
        return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return number.toString();
}
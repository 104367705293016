export const getBusinessType = (state) => {
    switch (state) {
        case 1:
            return "Restaurant";
        case 2:
            return "Medical Center";
        case 3:
            return "New Company";
        case 4:
            return "Product";
        case 5:
            return "Other";
        default:
            break;
    }
};
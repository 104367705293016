import { createSlice } from '@reduxjs/toolkit';
import { DoneRequestAction, GetRequestsAction, ShowRequestAction } from '../actions/RequestAction';

export const requestReducer = createSlice({
    name: "Request Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        requests: [],
        requestInfo: [],
        requestImages: [],
    },
    reducers: {
        clearRequests(state) {
            state.requests = [];
        },
        clearRequestInfo(state) {
            state.requestInfo = [];
        },
        clearRequestImages(state) {
            state.requestImages = [];
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetRequestsAction.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.requests = [
                    ...state.requests,
                    ...payload.data.data.filter((newRequest) => {
                        return !state.requests.some((request) => request.id === newRequest.id);
                    })
                ];
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetRequestsAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(ShowRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(ShowRequestAction.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.requestInfo = payload.data.request
                state.requestImages = payload.data.social_media_images
            })
            .addCase(ShowRequestAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(DoneRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DoneRequestAction.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(DoneRequestAction.rejected, (state) => {
                state.loading = false;
            })
    }
});

export const {
    clearRequestInfo,
    clearRequestImages,
    clearRequests
} = requestReducer.actions;
export default requestReducer.reducer;
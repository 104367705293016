import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { GetServicesAPI, UpdateServicesAPI } from "../Api";

export const GetServicesAction = createAsyncThunk("admin/services/get", async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(GetServicesAPI);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateServicesAction = createAsyncThunk("admin/services/update", async (info, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(UpdateServicesAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
import { createSlice } from '@reduxjs/toolkit';
import { ShowProfileAction, UpdateProfileAction } from '../actions/ProfileAction';

export const profileReducer = createSlice({
    name: "Profile Reducer",
    initialState: {
        loading: false,
        profileInfo: [],
        profileImages: [],
    },
    reducers: {
    },

    extraReducers: (builder) => {
        builder
            .addCase(ShowProfileAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(ShowProfileAction.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.profileInfo = payload.data.profile
                state.profileImages = payload.data.images
            })
            .addCase(ShowProfileAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(UpdateProfileAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateProfileAction.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(UpdateProfileAction.rejected, (state) => {
                state.loading = false;
            })
    }
});

export default profileReducer.reducer;
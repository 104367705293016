import React, { useEffect } from "react";
import "./ShowRequest.scss";
import { Image } from "primereact/image";
import { useNavigate, useParams } from "react-router-dom";
import {
  DoneRequestAction,
  ShowRequestAction,
} from "../../../redux/actions/RequestAction";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "../../../context/ToastContext";
import { getBusinessType } from "../../../redux/utils/businessType";
import { imageURL } from "../../../redux/Api";
import {
  clearRequestImages,
  clearRequestInfo,
  clearRequests,
} from "../../../redux/reducers/RequestReducer";

const ShowRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { requestInfo, requestImages } = useSelector((state) => state.requests);
  const { showError, showSuccess } = useToast();
  const { id } = useParams();

  useEffect(() => {
    dispatch(ShowRequestAction(id))
      .then(unwrapResult)
      .catch((err) => {
        showError(err);
      });
    return () => {
      dispatch(clearRequestInfo());
      dispatch(clearRequestImages());
      dispatch(clearRequests());
    };
  }, [id, dispatch, showError]);

  const doneHandler = () => {
    dispatch(DoneRequestAction(id))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message);
        navigate(-1);
      })
      .catch((err) => {
        showError(err);
      });
  };
  console.log(requestInfo.status)
  return (
    <div className="show-request">
      {requestInfo.status === 1 && <div className="done">Done ✓</div>}
      <h2 className="show-request__title">Review Your Information</h2>
      <div className="show-request__content">
        <p>
          <strong>Full Name:</strong> {requestInfo.full_name}
        </p>
        <p>
          <strong>Email:</strong> {requestInfo.email}
        </p>
        <p>
          <strong>Whatsapp Number:</strong> {requestInfo.whatsapp_number}
        </p>
        <p>
          <strong>Location:</strong> {requestInfo.address}
        </p>
        <p>
          <strong>Business Name:</strong> {requestInfo.business_name}
        </p>
        <p>
          <strong>Business Type:</strong>{" "}
          {getBusinessType(requestInfo.business_type)}
        </p>
        <p>
          <strong>Description:</strong> {requestInfo.description}
        </p>
        <p>
          <strong>Preferred AD Date:</strong> {requestInfo.ad_date}
        </p>
        <strong>Social Media Images:</strong>
        <div className="show-request__images">
          {requestImages &&
            requestImages.map((image, index) => (
              <Image
                key={index}
                src={imageURL + image.path}
                alt={`Social Media ${index + 1}`}
                preview={imageURL + image.path}
              />
            ))}
        </div>
      </div>

      <div className="actions">
        <button
          type="button"
          className="cta"
          onClick={() => {
            navigate(-1);
          }}
        >
          <span>Back</span>
        </button>
        {requestInfo.status === 0 && (
          <button type="submit" className="cta" onClick={doneHandler}>
            <span>Mark as done</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ShowRequest;

import React from "react";
import AppRoutes from "./routes";
import LoadingFS from "./components/loading/LoadingFS";

const App = () => {
  return (
    <div className="app">
      <LoadingFS />
      <AppRoutes />
    </div>
  );
};

export default App;

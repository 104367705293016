import React, { useEffect, useState } from "react";
import "./EditProfile.scss";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ShowProfileAction,
  UpdateProfileAction,
} from "../../../redux/actions/ProfileAction";
import { useToast } from "../../../context/ToastContext";
import { unwrapResult } from "@reduxjs/toolkit";
import { imageURL } from "../../../redux/Api";

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showError, showSuccess } = useToast();
  const { profileImages, profileInfo } = useSelector((state) => state.profile);

  const [profile, setProfile] = useState({
    name: "",
    bio: "",
    location: "",
    phone: "",
    profileImage: "",
    images: [],
    social: {
      facebook: "",
      instagram: "",
      tiktok: "",
      email: "",
    },
    instagramActivity: {
      posts: "",
      followers: "",
      following: "",
    },
  });

  useEffect(() => {
    dispatch(ShowProfileAction())
      .then(unwrapResult)
      .catch((err) => {
        showError(err);
      });
  }, [dispatch, showError]);

  useEffect(() => {
    if (profileInfo.length !== 0 || profileImages.length !== 0) {
      setProfile({
        name: profileInfo.name,
        bio: profileInfo.bio,
        location: profileInfo.location,
        phone: profileInfo.phone_number,
        profileImage: profileInfo.image.path,
        images: profileImages.map((image) => image),
        social: {
          facebook: profileInfo.facebook,
          instagram: profileInfo.instagram,
          tiktok: profileInfo.tiktok,
          email: profileInfo.email,
        },
        instagramActivity: {
          posts: profileInfo.count_posts,
          followers: profileInfo.count_followers,
          following: profileInfo.count_following,
        },
      });
    }
  }, [profileInfo, profileImages]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSocialChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, social: { ...profile.social, [name]: value } });
  };

  const handleInstaActivityChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      instagramActivity: { ...profile.instagramActivity, [name]: value },
    });
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    setProfile({
      ...profile,
      profileImage: { file, preview: URL.createObjectURL(file) },
    });
  };

  const handleImageChange = (id, index, e) => {
    const file = e.target.files[0];
    const newImages = [...profile.images];
    newImages[index] = { id: id, file, preview: URL.createObjectURL(file) };
    setProfile({ ...profile, images: newImages });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Append basic profile information
    formData.append("name", profile.name);
    formData.append("email", profile.social.email);
    formData.append("phone_number", profile.phone);
    formData.append("bio", profile.bio);
    formData.append("location", profile.location);
    formData.append("facebook", profile.social.facebook);
    formData.append("instagram", profile.social.instagram);
    formData.append("tiktok", profile.social.tiktok);
    formData.append("count_posts", profile.instagramActivity.posts);
    formData.append("count_followers", profile.instagramActivity.followers);
    formData.append("count_following", profile.instagramActivity.following);

    // Append profile image
    if (profile.profileImage.file) {
      formData.append("image", profile.profileImage.file);
    }

    // Append additional images
    profile.images.forEach((image, index) => {
      if (image.file) {
        formData.append(`images[${index}][image]`, image.file);
        formData.append(`images[${index}][id]`, image.id);
        console.log(image);
      }
    });

    dispatch(UpdateProfileAction(formData))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message);
        navigate(-1);
      })
      .catch((err) => {
        showError(err);
      });
  };

  return (
    <div className="edit-profile" id="edit-profile">
      <form className="profile-card" onSubmit={submitHandler}>
        <div className="images-wrapper">
          <div className="wrapper">
            <span className="title">Images</span>
            <Carousel
              autoFocus
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              showArrows={true}
              stopOnHover={true}
              infiniteLoop
              autoPlay
              axis="horizontal"
            >
              {profile.images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image.preview ?? imageURL + image.path}
                    alt={`profile ${index}`}
                  />
                  <input
                    type="file"
                    onChange={(e) => handleImageChange(image.id, index, e)}
                    accept="image/*"
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div className="profile-image-upload">
            <span className="title">Profile Image</span>
            <img
              src={
                profile.profileImage.preview ?? imageURL + profile.profileImage
              }
              alt="profile"
            />
            <input
              type="file"
              onChange={handleProfileImageChange}
              accept="image/*"
            />
          </div>
        </div>

        <div className="instagram-activity">
          <input
            type="number"
            name="posts"
            placeholder="Posts"
            value={profile.instagramActivity.posts}
            onChange={handleInstaActivityChange}
          />
          <input
            type="number"
            name="followers"
            placeholder="Followers"
            value={profile.instagramActivity.followers}
            onChange={handleInstaActivityChange}
          />
          <input
            type="number"
            name="following"
            placeholder="Following"
            value={profile.instagramActivity.following}
            onChange={handleInstaActivityChange}
          />
        </div>

        <div className="content">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={profile.name}
            onChange={handleChange}
          />
          <textarea
            rows={5}
            name="bio"
            placeholder="Bio"
            value={profile.bio}
            onChange={handleChange}
          ></textarea>
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={profile.location}
            onChange={handleChange}
          />
          <input
            type="text"
            name="phone"
            placeholder="Phone Number"
            value={profile.phone}
            onChange={handleChange}
          />

          <div className="social-links">
            <div className="flex">
              <FaFacebook className="icon" />
              <input
                type="text"
                name="facebook"
                placeholder="Facebook"
                value={profile.social.facebook}
                onChange={handleSocialChange}
              />
            </div>

            <div className="flex">
              <FaInstagram className="icon" />
              <input
                type="text"
                name="instagram"
                placeholder="Instagram"
                value={profile.social.instagram}
                onChange={handleSocialChange}
              />
            </div>

            <div className="flex">
              <FaTiktok className="icon" />
              <input
                type="text"
                name="tiktok"
                placeholder="Tiktok"
                value={profile.social.tiktok}
                onChange={handleSocialChange}
              />
            </div>

            <div className="flex">
              <IoMail className="icon" />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={profile.social.email}
                onChange={handleSocialChange}
              />
            </div>
          </div>
        </div>

        <div className="actions">
          <button
            type="button"
            className="cta"
            onClick={() => {
              navigate(-1);
            }}
          >
            <span>Cancel</span>
          </button>
          <button type="submit" className="cta">
            <span>Update</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DoneRequestAPI, GetRequestsAPI, ShowRequestAPI } from "../Api";
import axiosInstance, { errorMessage } from "../constant";

export const GetRequestsAction = createAsyncThunk("admin/request/get", async (info, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(GetRequestsAPI + info.page + "&size=" + info.size + "&isPaginate=1");
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const ShowRequestAction = createAsyncThunk("admin/request/show", async (id, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(ShowRequestAPI + id);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DoneRequestAction = createAsyncThunk("admin/request/done", async (id, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(DoneRequestAPI + id);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
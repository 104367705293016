import { Button } from "primereact/button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../../context/ToastContext";
import {
  DeletePostAction,
  GetPostsAction,
} from "../../../redux/actions/GalleryAction";
import { unwrapResult } from "@reduxjs/toolkit";

const DeleteVideo = ({ currentId, deleteModal }) => {
  const dispatch = useDispatch();
  const { showError, showSuccess } = useToast();
  const { loading } = useSelector((state) => state.gallery);

  const deleteHandler = () => {
    dispatch(DeletePostAction(currentId))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message);
        dispatch(GetPostsAction());
        deleteModal(false);
      })
      .catch((err) => {
        showError(err);
      });
  };
  return (
    <div>
      <div className="input-wrapper">
        <h4>Are you sure you want to delete this video ?</h4>
      </div>
      <span className="actions">
        <Button
          size="small"
          className="dark"
          label="Cancel"
          icon="pi pi-times"
          onClick={() => deleteModal(false)}
        />
        <Button
          size="small"
          className="danger"
          label="Delete"
          icon="pi pi-trash"
          type="submit"
          loading={loading}
          onClick={deleteHandler}
        />
      </span>
    </div>
  );
};

export default DeleteVideo;

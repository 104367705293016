// export const baseURL = 'https://cp.zayna.mr-appss.com/back-end/public/index.php/api/';
export const baseURL =
  "https://advertisements.it.mr-appss.com/public/index.php/api/";

// export const imageURL = 'https://cp.zayna.mr-appss.com/back-end/public';
export const imageURL = "https://advertisements.it.mr-appss.com/public";
// export const baseURL = 'http://192.168.0.201:8000/api/';
// export const imageURL = 'http://192.168.0.201:8000';

export const loginAPI = `v1/login`
export const logoutAPI = `v1/cms/logout`

export const ChangePasswordAPI = `v1/cms/password/change`

export const ShowProfileAPI = `v1/cms/profile`
export const UpdateProfileAPI = `v1/cms/profile/update`

//Services
export const GetServicesAPI = `v1/cms/services`
export const UpdateServicesAPI = `v1/cms/services/update`

//Gellery
export const AddPostAPI = `v1/cms/galleries/add`
export const GetPostsAPI = `v1/cms/galleries`
export const ShowPostAPI = `v1/cms/galleries/`
export const DeletePostAPI = `v1/cms/galleries/`

//Requests
export const GetRequestsAPI = `v1/cms/requests?page=`
export const ShowRequestAPI = `v1/cms/requests/`
export const DoneRequestAPI = `v1/cms/requests/done/`
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from './components/notfound/NotFound';
import Login from './pages/login/Login';
import Main from './pages/main/Main';
import ChangePassword from './pages/settings/change-password/ChangePassword';
import Profile from './pages/profile/Profile';
import EditProfile from './pages/profile/actions/EditProfile';
import Navbar from './components/navbar/Navbar';
import Services from './pages/services/Services';
import Gallery from './pages/gallery/Gallery';
import Requests from './pages/requests/Requests';
import ShowRequest from './pages/requests/actions/ShowRequest';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Login />} />
                <Route path="main" element={<Main />} />
                <Route path="services" element={<Services />} />
                <Route path="gallery" element={<Gallery />} />

                <Route path='/requests'>
                    <Route index element={<Requests />} />
                    <Route path=':id' element={<ShowRequest />} />
                </Route>

                <Route path='/profile'>
                    <Route index element={<Profile />} />
                    <Route path='edit' element={<EditProfile />} />
                </Route>

                <Route path="change-password" element={<ChangePassword />} />
            </Routes >
        </BrowserRouter>
    );
};

export default AppRoutes;

import { createSlice } from '@reduxjs/toolkit';
import { GetServicesAction, UpdateServicesAction } from '../actions/ServicesAction';

export const servicesReducer = createSlice({
    name: "Service Reducer",
    initialState: {
        loading: false,
        servicesInfo: [],
    },
    reducers: {
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetServicesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetServicesAction.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.servicesInfo = payload.data
            })
            .addCase(GetServicesAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(UpdateServicesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateServicesAction.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(UpdateServicesAction.rejected, (state) => {
                state.loading = false;
            })
    }
});

export default servicesReducer.reducer;
import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { ShowPostAction } from "../../redux/actions/GalleryAction";
import { imageURL } from "../../redux/Api";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "../../context/ToastContext";
import "./VideoCard.scss";

const VideoCard = ({ id, poster, title, deleteVideo }) => {
  const [videoSrc, setVideoSrc] = useState("");
  const videoRef = useRef(null); // Add a ref for the video element
  const dispatch = useDispatch();
  const { showError } = useToast();

  const fetchVideoSource = async (id) => {
    try {
      const result = await dispatch(ShowPostAction(id));
      const data = unwrapResult(result);
      return imageURL + data.data.video.path;
    } catch (err) {
      showError(err.message);
      return null;
    }
  };

  const handleVideoClick = async () => {
    if (!videoSrc) {
      const src = await fetchVideoSource(id);
      if (src) {
        setVideoSrc(src);
        if (videoRef.current) {
          videoRef.current.src = src; // Directly set the video src
          videoRef.current.load(); // Reload the video element to update the source
          try {
            await videoRef.current.play(); // Play the video
            console.log("Video is playing");
          } catch (error) {
            console.error("Error attempting to play", error);
          }
        }
      }
    }
  };

  return (
    <div className="video-card">
      <div className="header">
        <h3>{title}</h3>
        <Button
          size="small"
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          aria-label="Submit"
          onClick={() => deleteVideo(id, true)}
        />
      </div>
      <video
        ref={videoRef}
        controls={videoSrc ? true : false}
        poster={poster}
        onClick={handleVideoClick}
      >
        {videoSrc && <source src={videoSrc} type="video/mp4" />}
      </video>
    </div>
  );
};

export default VideoCard;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import "./assets/styles/global.scss";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import { ToastProvider } from "./context/ToastContext.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <ToastProvider>
        <App />
    </ToastProvider>
  </Provider>
);

import React, { useEffect } from "react";
import "./Profile.scss";
import {
  FaFacebook,
  FaInstagram,
  FaPhone,
  FaTiktok,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { formatNumberWithAbbreviations } from "../../utils/Formatters";
import { Carousel } from "react-responsive-carousel";
import Anchor from "../../components/anchor/Anchor";
import { useDispatch, useSelector } from "react-redux";
import { ShowProfileAction } from "../../redux/actions/ProfileAction";
import { imageURL } from "../../redux/Api";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "../../context/ToastContext";

const Profile = () => {
  const dispatch = useDispatch();
  const { showError } = useToast();
  const { profileImages, profileInfo } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(ShowProfileAction())
      .then(unwrapResult)
      .catch((err) => {
        showError(err);
      });
  }, [dispatch, showError]);

  if (profileInfo.length === 0 || profileImages.length === 0) {
    return null;
  }

  return (
    <div className="profile" id="about-me">
      <div className="profile-card">
        <div className="anchor">
          <Anchor to={"edit"} label={"Edit"} />
        </div>
        <Carousel
          autoFocus
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          showArrows={false}
          stopOnHover={false}
          infiniteLoop
          transitionTime={1000}
          autoPlay
          axis="horizontal"
        >
          {profileImages.map((image, index) => (
            <img
              key={index}
              src={imageURL + image.path}
              alt={`profile-${index}`}
            />
          ))}
        </Carousel>
        <div className="content">
          <div className="profile-image">
            <img
              src={imageURL + profileInfo.image.path}
              alt={profileInfo.name}
            />
            <h1>{profileInfo.name}</h1>
          </div>
          <p className="bio">{profileInfo.bio}</p>

          <h2>Instagram Information</h2>
          <div className="instagram-info">
            <div>
              <div className="number">Posts</div>
              <span>
                {formatNumberWithAbbreviations(profileInfo.count_posts)}
              </span>
            </div>
            <div>
              <div className="number">Followers</div>
              <span>
                {formatNumberWithAbbreviations(profileInfo.count_followers)}
              </span>
            </div>
            <div>
              <div className="number">Following</div>
              <span>
                {formatNumberWithAbbreviations(profileInfo.count_following)}
              </span>
            </div>
          </div>

          <div className="label">
            <FaMapMarkerAlt className="icon" />
            <span>{profileInfo.location}</span>
          </div>
          <div className="label">
            <FaPhone className="icon" />
            <span>{profileInfo.phone_number}</span>
          </div>

          <div className="social-links">
            <a
              href={profileInfo.facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="icon" />
            </a>
            <a
              href={profileInfo.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="icon" />
            </a>
            <a
              href={profileInfo.tiktok}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTiktok className="icon" />
            </a>
            <a
              href={`mailto:${profileInfo.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoMail className="icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

import React from "react";
import "./RequestCard.scss";
import Anchor from "../anchor/Anchor";

const RequestCard = ({
  id,
  fullName,
  email,
  businessName,
  businessType,
  preferredAdDate,
  status,
}) => {
  return (
    <div className="request-card">
      <div className={`${status === 1 && "status"}`}></div>
      <h2 className="request-card__title">From {fullName}</h2>
      <div className="request-card__content">
        <p>
          <strong>Full Name:</strong> {fullName}
        </p>
        <p>
          <strong>Email:</strong> {email}
        </p>
        <p>
          <strong>Business Name:</strong> {businessName}
        </p>
        <p>
          <strong>Business Type:</strong> {businessType}
        </p>
        <p>
          <strong>Preferred AD Date:</strong> {preferredAdDate}
        </p>
      </div>
      <div className="actions">
        <Anchor to={`${id}`} label={"Show"} />
      </div>
    </div>
  );
};

export default RequestCard;

// ToastContext.js
import React, { createContext, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const toastRef = useRef(null);

  const showSuccess = (msg) => {
    toastRef.current.show({
      severity: 'success',
      summary: 'Success',
      detail: msg,
      life: 3000,
    });
  };

  const showError = (msg) => {
    toastRef.current.show({
      severity: 'error',
      summary: 'Error',
      detail: msg,
      life: 3000,
    });
  };

  const showInfo = (msg) => {
    toastRef.current.show({
      severity: 'info',
      summary: 'Information',
      detail: msg,
      life: 3000,
    });
  };

  const showWarn = (msg) => {
    toastRef.current.show({
      severity: 'warn',
      summary: 'Warning',
      detail: msg,
      life: 3000,
    });
  };

  return (
    <ToastContext.Provider value={{ showSuccess, showError, showInfo, showWarn }}>
      <Toast ref={toastRef} position='top-center' />
      {children}
    </ToastContext.Provider>
  );
};

import React from "react";
import { IoMdLogOut } from "react-icons/io";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbPasswordFingerprint } from "react-icons/tb";
import { useToast } from "../../context/ToastContext";
import { unwrapResult } from "@reduxjs/toolkit";
import { LogOutAction } from "../../redux/actions/AuthAction";
import { HiMiniHome } from "react-icons/hi2";
import "./Navbar.scss";

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { showError, showSuccess } = useToast();
  const navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(LogOutAction())
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message);
        navigate("/");
      })
      .catch((err) => {
        showError(err);
      });
  };

  if (location.pathname === "/") {
    return null;
  }

  return (
    <header className="navbar">
      <div className="routes">
        <img src="/logo.png" alt="zayna-barakat" />
      </div>
      <div className="options">
        <Link to="main">
          <HiMiniHome className="icon" />
        </Link>
        <Link to="change-password">
          <TbPasswordFingerprint className="icon" />
        </Link>
        <IoMdLogOut className="icon" onClick={logoutHandler} />
      </div>
    </header>
  );
};

export default Navbar;

import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../../context/ToastContext";
import {
  AddPostAction,
  GetPostsAction,
} from "../../../redux/actions/GalleryAction";
import { unwrapResult } from "@reduxjs/toolkit";
import { ProgressBar } from "primereact/progressbar";

const AddVideo = ({ updateModal }) => {
  const dispatch = useDispatch();
  const { showError, showSuccess } = useToast();
  const { loading } = useSelector((state) => state.gallery);
  const [videoFile, setVideoFile] = useState("");
  const [posterFile, setPosterFile] = useState("");
  const [title, setTitle] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("title", title);
    formData.append("image", posterFile);
    formData.append("video", videoFile);

    dispatch(AddPostAction(formData))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message);
        updateModal(false);
        dispatch(GetPostsAction());
      })
      .catch((err) => {
        showError(err);
        updateModal(false);
      });
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className="input-wrapper">
          <div className="p-inputgroup">
            <InputText
              required
              type="text"
              className="custom-input"
              placeholder="Video Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="input-wrapper file-upload">
          <h4 className="">Poster video image</h4>
          <FileUpload
            mode="basic"
            chooseLabel="Choose an image"
            cancelLabel="Cancel"
            accept="image/*"
            onClear={(event) => setPosterFile(null)}
            onSelect={(event) => setPosterFile(event.files[0])}
          />
        </div>

        <div className="input-wrapper file-upload">
          <h4 className="">Upload a video</h4>
          <FileUpload
            mode="basic"
            chooseLabel="Choose a video"
            cancelLabel="Cancel"
            accept="video/*"
            onClear={(event) => setVideoFile(null)}
            onSelect={(event) => setVideoFile(event.files[0])}
          />
        </div>

        {loading && (
          <ProgressBar
            mode="indeterminate"
            style={{ height: "6px" }}
          ></ProgressBar>
        )}

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => updateModal(false)}
          />
          <Button
            size="small"
            className="copper"
            label="Upload"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </>
  );
};

export default AddVideo;

import { createSlice } from '@reduxjs/toolkit';
import { AddPostAction, DeletePostAction, GetPostsAction, ShowPostAction } from '../actions/GalleryAction';

export const galleryReducer = createSlice({
    name: "Gallery Reducer",
    initialState: {
        loading: false,
        gallery: [],
    },
    reducers: {
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetPostsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetPostsAction.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.gallery = payload.data
            })
            .addCase(GetPostsAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(AddPostAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddPostAction.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(AddPostAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(ShowPostAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(ShowPostAction.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(ShowPostAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(DeletePostAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeletePostAction.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(DeletePostAction.rejected, (state) => {
                state.loading = false;
            })
    }
});

export default galleryReducer.reducer;
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import "./Services.scss";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../context/ToastContext";
import { GetServicesAction, UpdateServicesAction } from "../../redux/actions/ServicesAction";
import { unwrapResult } from "@reduxjs/toolkit";
import { imageURL } from "../../redux/Api";

const Services = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showError, showSuccess } = useToast();
  const { servicesInfo } = useSelector((state) => state.services);

  const [services, setServices] = useState([
    {
      name: "",
      description: "",
      image: { path: "", file: null, preview: null },
    },
    {
      name: "",
      description: "",
      image: { path: "", file: null, preview: null },
    },
    {
      name: "",
      description: "",
      image: { path: "", file: null, preview: null },
    },
  ]);

  useEffect(() => {
    dispatch(GetServicesAction())
      .then(unwrapResult)
      .catch((err) => {
        showError(err);
      });
  }, [dispatch, showError]);

  useEffect(() => {
    if (servicesInfo && servicesInfo.length > 0) {
      setServices(
        servicesInfo.map((service) => ({
          name: service.name,
          description: service.description,
          image: { path: service.image.path, file: null, preview: null },
        }))
      );
    }
  }, [servicesInfo]);

  const handleImageChange = (index, e) => {
    const file = e.target.files[0];
    const newServices = [...services];
    newServices[index].image = { file, preview: URL.createObjectURL(file) };
    setServices(newServices);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newServices = [...services];
    newServices[index][name] = value;
    setServices(newServices);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    services.forEach((service, index) => {
      formData.append(`services[${index}][name]`, service.name);
      formData.append(`services[${index}][description]`, service.description);
      if (service.image.file) {
        formData.append(`services[${index}][image]`, service.image.file);
      }
    });

    dispatch(UpdateServicesAction(formData))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message);
      })
      .catch((err) => {
        showError(err);
      });
  };

  return (
    <div className="services">
      <form className="service-card" onSubmit={submitHandler}>
        <div className="wrapper">
          <span className="title">Images</span>
          <Carousel
            autoFocus
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            showArrows={true}
            stopOnHover={true}
            infiniteLoop
            autoPlay
            axis="horizontal"
          >
            {services.map((service, index) => (
              <div key={index}>
                <img
                  src={service.image.preview ?? imageURL + service.image.path}
                  alt={`service ${index}`}
                />
                <input
                  type="file"
                  onChange={(e) => handleImageChange(index, e)}
                  accept="image/*"
                />
              </div>
            ))}
          </Carousel>
        </div>

        <div className="content">
          {services.map((service, index) => (
            <div key={index}>
              <input
                type="text"
                name="name"
                placeholder={`Title ${index + 1}`}
                value={service.name}
                onChange={(e) => handleChange(index, e)}
              />
              <textarea
                rows={5}
                name="description"
                placeholder={`Description ${index + 1}`}
                value={service.description}
                onChange={(e) => handleChange(index, e)}
              ></textarea>
            </div>
          ))}
        </div>
        <div className="actions">
          <button
            type="button"
            className="cta"
            onClick={() => {
              navigate(-1);
            }}
          >
            <span>Cancel</span>
          </button>
          <button type="submit" className="cta">
            <span>Update</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Services;

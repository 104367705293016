import React, { useEffect, useState } from "react";
import VideoCard from "../../components/video-card/VideoCard";
import { Dialog } from "primereact/dialog";
import AddVideo from "./actions/AddVideo";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { GetPostsAction } from "../../redux/actions/GalleryAction";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "../../context/ToastContext";
import { imageURL } from "../../redux/Api";
import DeleteVideo from "./actions/DeleteVideo";
import "./Gallery.scss";

const Gallery = () => {
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const { showError } = useToast();
  const { gallery } = useSelector((state) => state.gallery);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetPostsAction())
      .then(unwrapResult)
      .catch((err) => {
        showError(err);
      });
  }, [dispatch, showError]);

  return (
    <div className="gallery">
      <div className="header">
        Here you can add a video file, title and poster for it.
        <Button
          size="small"
          icon="pi pi-plus"
          className="p-button-rounded p-button-text p-button-success"
          aria-label="Submit"
          onClick={() => setUpdateModal(true)}
        />
      </div>

      <div className="gallery-container">
        {gallery?.map((item, index) => {
          return (
            <VideoCard
              deleteVideo={(id, status) => {
                setCurrentId(id);
                setDeleteModal(status);
              }}
              id={item.id}
              poster={imageURL + item.image.path}
              title={item.title}
              key={index}
            />
          );
        })}
      </div>

      <Dialog
        header="Video Upload"
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <AddVideo updateModal={(e) => setUpdateModal(e)} />
      </Dialog>

      <Dialog
        header="Delete Video"
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <DeleteVideo
          deleteModal={(e) => setDeleteModal(e)}
          currentId={currentId}
        />
      </Dialog>
    </div>
  );
};

export default Gallery;

import { createSlice } from '@reduxjs/toolkit';
import { ChangePasswordAction, LogInAction, LogOutAction } from '../actions/AuthAction';
export const authReducer = createSlice({
    name: "Auth Reducer",
    initialState: {
        loading: false,
        fcmWarning: false
    },
    reducers: {
        fcmToggle: (state, action) => {
            state.fcmWarning = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(LogInAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(LogInAction.fulfilled, (state, { payload }) => {
                localStorage.setItem("token", payload.data.access_token)
                state.loading = false;
            })
            .addCase(LogInAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(LogOutAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(LogOutAction.fulfilled, (state, { payload }) => {
                localStorage.setItem("token", undefined)
                state.loading = false;
            })
            .addCase(LogOutAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(ChangePasswordAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(ChangePasswordAction.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(ChangePasswordAction.rejected, (state) => {
                state.loading = false;
            })
    }
});
export default authReducer.reducer;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddPostAPI, DeletePostAPI, GetPostsAPI, ShowPostAPI, } from "../Api";
import axiosInstance, { errorMessage } from "../constant";

export const AddPostAction = createAsyncThunk("admin/gallery/add", async (info, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(AddPostAPI, info);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetPostsAction = createAsyncThunk("admin/gallery/get", async (_, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(GetPostsAPI);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const ShowPostAction = createAsyncThunk("admin/gallery/show", async (id, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(ShowPostAPI + id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeletePostAction = createAsyncThunk("admin/gallery/delete", async (id, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.delete(DeletePostAPI + id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
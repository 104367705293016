import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { IoMdMailOpen } from "react-icons/io";
import { LogInAction } from "../../redux/actions/AuthAction";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../context/ToastContext";
import { useNavigate } from "react-router-dom";
import { MdOutlinePassword } from "react-icons/md";
import "./Login.scss";

const Login = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const { showError, showSuccess } = useToast();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("email", email);
    data.append("password", password);

    dispatch(LogInAction(data))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message);
        navigate("/main");
      })
      .catch((err) => {
        showError(err);
      });
  };

  return (
    <div className="login">
      <div className="login-section">
        <div className="header">
          <img src={"/logo.png"} alt="elite-services" />
          <h2>Hi Zayna!</h2>
          <h3>Zayna Control Panel</h3>
        </div>
        <form onSubmit={submitHandler}>
          <div className="input-wrapper">
            <h4>Email</h4>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <IoMdMailOpen className="icon" />
              </span>
              <InputText
                autoFocus
                type="email"
                placeholder="Email"
                required
                className="custom-input"
                unstyled
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="input-wrapper">
            <h4>Password</h4>
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <MdOutlinePassword className="icon" />
              </span>
              <InputText
                type="password"
                placeholder="Password"
                required
                className="custom-input"
                unstyled
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>

          <Button
            className="copper"
            label="Login"
            type="submit"
            loading={loading}
          ></Button>
        </form>
      </div>

      <div className="login-image">
        <img
          src={`${require("../../assets/images/startup.png")}`}
          alt="elite-services"
        />
      </div>
    </div>
  );
};

export default Login;

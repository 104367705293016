import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { ChangePasswordAction } from "../../../redux/actions/AuthAction";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useToast } from "../../../context/ToastContext";
import "./ChangePassword.scss";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { showError, showSuccess } = useToast();

  const handleChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let info = new FormData();
    info.append("old_password", oldPassword);
    info.append("new_password", newPassword);
    info.append("confirm_password", confirmPassword);
    dispatch(ChangePasswordAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((err) => {
        showError(err);
      });
  };

  return (
    <div className="change-password container">
      <form className="content" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <h4>Old Password</h4>
          <div className="p-inputgroup">
            <InputText
              unstyled
              className="custom-input"
              onChange={handleChangeOldPassword}
              value={oldPassword}
              type="password"
              placeholder="Old Password"
              required
            />
          </div>
        </div>
        <div className="input-wrapper">
          <h4>New Password</h4>
          <div className="p-inputgroup">
            <InputText
              unstyled
              className="custom-input"
              onChange={handleChangeNewPassword}
              value={newPassword}
              type="password"
              placeholder="New Password"
              required
            />
          </div>
        </div>
        <div className="input-wrapper">
          <h4>Confirm Password</h4>
          <div className="p-inputgroup">
            <InputText
              unstyled
              className="custom-input"
              onChange={handleChangeConfirmPassword}
              value={confirmPassword}
              type="password"
              placeholder="Confirm Password"
              required
            />
          </div>
        </div>
        <div className="actions">
          <button
            type="button"
            className="cta"
            onClick={() => {
              navigate(-1);
            }}
          >
            <span>Cancel</span>
          </button>
          <button type="submit" className="cta">
            <span>Submit</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;

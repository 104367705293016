import React, { useEffect, useState } from "react";
import RequestCard from "../../components/request-card/RequestCard";
import "./Requests.scss";
import { GetRequestsAction } from "../../redux/actions/RequestAction";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../context/ToastContext";
import { unwrapResult } from "@reduxjs/toolkit";
import { getBusinessType } from "../../redux/utils/businessType";

const Requests = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { requests, totalItems } = useSelector((state) => state.requests);
  const { showError } = useToast();
  const handleLoadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    let info = { size: 9, page: page };

    dispatch(GetRequestsAction(info))
      .then(unwrapResult)
      .catch((err) => {
        showError(err);
      });
  }, [dispatch, showError, page]);

  return (
    <div className="requests">
      <div className="requests-container">
        {requests.map((request) => (
          <RequestCard
            id={request.id}
            key={request.id}
            fullName={request.full_name}
            email={request.email}
            businessName={request.business_name}
            businessType={getBusinessType(request.business_type)}
            preferredAdDate={request.ad_date}
            status={request.status}
          />
        ))}
      </div>
      {requests?.length === 0 && (
        <div className="empty">
          <h1>No Requests Available !!</h1>
        </div>
      )}

      {totalItems !== requests.length && (
        <div className="action">
          <button type="submit" className="cta" onClick={handleLoadMore}>
            <span>Load More</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Requests;

import React from 'react'
import './loadingFS.scss'
import { useSelector } from 'react-redux';

const LoadingFS = () => {
    const isLoading = useSelector((state) =>
        state.services.loading ||
        state.profile.loading ||
        state.gallery.loading ||
        state.requests.loading ||
        state.auth.loading,
    );

    if (!isLoading) return null;

    return (
        <div className='center'>
            <div className="lds-hourglass"></div>
        </div>
    )
}
export default LoadingFS
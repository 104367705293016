import React from "react";
import { RiServiceLine } from "react-icons/ri";
import { TiInfoLarge } from "react-icons/ti";
import { PiUsersThreeLight } from "react-icons/pi";
import { Link } from "react-router-dom";
import "./Main.scss";

const Main = () => {
  return (
    <div className="main">
      <div className="container">
        <Link to="/profile">
          <div className="card">
            <TiInfoLarge className="icon" />
            <span>Profile</span>
            <p>
              This section allows you to update your biography, change profile
              images, manage social media links, and update personal information
              that will be displayed on the website.
            </p>
          </div>
        </Link>

        <Link to="/services">
          <div className="card">
            <RiServiceLine className="icon" />
            <span>Services</span>
            <p>Here, you can showcase the services you provide.</p>
          </div>
        </Link>

        <Link to="/requests">
          <div className="card">
            <PiUsersThreeLight className="icon" />
            <span>Requests</span>
            <p>
              This section displays the services that users have requested from
              you.
            </p>
          </div>
        </Link>

        <Link to="/gallery">
          <div className="card">
            <RiServiceLine className="icon" />
            <span>Gallery</span>
            <p>
              Here, you can manage your previous work, such as adding vedios and
              posters.
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Main;
